<!--设备运维：设备列表-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">设备列表</h1>
          </div>
        </div>
        <div class="table-box">
          <el-tabs v-model="activeName" type="card" @tab-click="getDeviceListById">
            <!--印刷设备-->
            <el-tab-pane
              v-for="item in devicePrintList"
              :key="item.id"
              :label="item.name"
              :name="item.id"
            >
              <div v-if="managePermission" class="control-box">
                <!--添加按钮-->
                <div class="cb-left">
                  <el-input
                    v-model="form.name"
                    placeholder="设备名称"
                    style="width: 200px; margin-left: 10px"
                    class="control-item"
                    clearable
                    @keyup.enter.native="getDeviceList"
                  />

                  <el-button
                    class="control-item"
                    type="primary"
                    icon="el-icon-search"
                    @click="getDeviceList"
                  >
                    搜索
                  </el-button>
                </div>
                <div class="cb-right">
                  <el-button
                    class="control-item"
                    type="primary"
                    style="margin-left: 10px;"
                    icon="el-icon-edit"
                    @click="toAdd()"
                  >
                    增加
                  </el-button>
                </div>
              </div>
              <!--表格-->
              <div class="table-box">
                <el-table
                  v-loading="listLoading"
                  :data="DeviceList"
                  border
                  highlight-current-row
                  style="width: 100%"
                >
                  <!--序号-->
                  <el-table-column label="序号" align="center" width="80">
                    <template slot-scope="scope">
                      {{ scope.row.iOrder }}
                    </template>
                  </el-table-column>
                  <el-table-column label="设备名称" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="机身编号" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.code }}
                    </template>
                  </el-table-column>
                  <el-table-column label="设备类型" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.printType }}-{{ scope.row.deviceType }}
                    </template>
                  </el-table-column>
                  <el-table-column label="品牌型号" align="center">
                    <template slot-scope="scope">
<!--                      <div v-if="scope.row.brand === '其它'">-->
<!--                        {{ scope.row.brand }}-{{ scope.row.brandStr }}-->
<!--                      </div>-->
<!--                      <div v-if="scope.row.brand !== '其它'">-->
                      <span>{{ scope.row.brand }}</span>
                      <span v-if="scope.row.series">-{{ scope.row.series }}</span>
                      <span v-if="scope.row.model">-{{ scope.row.model }}</span>

<!--                      </div>-->
                    </template>
                  </el-table-column>

                  <!--操作-->
                  <el-table-column label="操作" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="updateDevice(scope.row,1)">
                        查看
                      </el-button>
                      <el-button type="primary" size="mini" @click="updateDevice(scope.row,2)">
                        编辑
                      </el-button>
                      <el-button
                        type="primary"
                        plain
                        v-if="managePermission"
                        size="mini"
                        icon="el-icon-delete"
                        @click="deleteDevice(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="form.count"
                  :total="totalCount"
                  :current-page="form.page + 1"
                  layout="total, sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';

export default {
  data() {
    return {
      devicePrintList: [],
      DeviceList: [],
      listLoading: false,
      form: {
        deviceId: '',
        page: 0,
        count: 10,
        name: '',
      },
      totalCount: 0,
      activeName: '',
      buttons: '',
    };
  },
  computed: {
    managePermission: {
      get() {
        return manageOauth('device_operate', this.buttons);
      },
    },
  },
  created() {
    API.userLog({ code: 'webDeviceList' }).then(() => {});
    if (this.$store.getters.userInfo.buttons) {
      this.buttons = this.$store.getters.userInfo.buttons;
    } else {
      this.buttons = sessionStorage.getItem('buttons');
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('buttons', JSON.stringify(this.$store.getters.userInfo.buttons));
    });
  },
  mounted() {
    this.getDevicePrintTypeList();
  },

  methods: {
    getDevicePrintTypeList() {
      API.getDevicePrintTypeList().then((response) => {
        this.devicePrintList = response.message.data;
        if (!response.message.data) {
          return;
        }
        for (const item of this.devicePrintList) {
          if (item.name === '印前设备') {
            item.name = '印前设备';
          }
          if (item.name === '印刷设备') {
            item.name = '印刷设备';
          }
          if (item.name === '印后设备') {
            item.name = '印后设备';
          }
        }
        this.form.deviceId = this.devicePrintList[0].id;
        this.activeName = this.devicePrintList[0].id;
        this.getDeviceList();
      });
    },
    back() {
      this.$router.go(-1);
    },
    getDeviceListById(tab) {
      this.form.deviceId = tab.name;
      this.form.page = 0;
      this.form.count = 10;
      this.getDeviceList();
    },
    getDeviceList() {
      API.getDeviceList(this.form).then((response) => {
        this.totalCount = response.message.data.totalCount;
        this.DeviceList = response.message.data.list;
      });
    },
    deleteDevice(val) {
      this.$confirm('确认删除设备, 是否继续?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        API.deleteDevice({ deviceId: val.id }).then((response) => {
          if (response.message.success) {
            this.getDeviceList();
            this.$message({
              message: response.message.message,
              showClose: true,
              type: 'success',
            });
          } else {
            this.$message({
              message: response.message.message,
              showClose: true,
              type: 'error',
            });
          }
        });
      });
    },
    toAdd() {
      this.$router.push('deviceAdd');
    },
    addDeviceShow() {
      this.$router.push('/device/deviceadd');
    },
    updateDevice(device,type) {
      if (type === 2) {
        this.$router.push({ path: 'deviceDetail', query: { id: device.id,isUpdate: type === 2 } });
      } else {
        this.$router.push({ path: 'deviceDetail', query: { id: device.id}});
      }
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.getDeviceList();
    },
    handleCurrentChange(val) {
      this.form.page = val - 1;
      this.getDeviceList();
    },
  },
};
</script>
<style scoped></style>
